import { PayloadAction } from "@reduxjs/toolkit";
import { getBranchTimeOffs_timeOffs_timeOffs } from "../../../../__generated/getBranchTimeOffs";
import { LeavesState } from "../leavesSlice";

const leaveEdited = (
  state: LeavesState,
  { payload }: PayloadAction<getBranchTimeOffs_timeOffs_timeOffs>
) => {
  state.timeOffs.forEach((timeOff, index) => {
    if (timeOff.id === payload.id) {
      state.timeOffs[index] = payload;
    }
  });

  if (state.selectedTimeOff?.id === payload.id) {
    state.selectedTimeOff = payload;
  }
};

export default leaveEdited;
