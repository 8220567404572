import { PayloadAction } from "@reduxjs/toolkit";
import { getFullHouses_fullHouses_fullHouses } from "../../../../__generated/getFullHouses";
import { ShiftTemplatesState } from "../shiftTemplatesSlice";

interface IPayload {
  setId: string;
  template: getFullHouses_fullHouses_fullHouses;
}
const templateAdded = (
  state: ShiftTemplatesState,
  { payload }: PayloadAction<IPayload>
) => {
  const { setId, template } = payload;

  if (state.selectedSet?.id === setId) {
    state.selectedTemplates.push(template);
  }

  if (state.sets.find((set) => set.id === setId)?.active) {
    state.activeTemplates.push(template);
  }
};

export default templateAdded;
