import { PayloadAction } from "@reduxjs/toolkit";
import { getNote_note_note_comments } from "../../../__generated/getNote";
import { NotesState } from "../notesSlice";

interface IPayload {
  noteId: string;
  comment: getNote_note_note_comments;
}

const commentAdded = (
  state: NotesState,
  { payload }: PayloadAction<IPayload>
) => {
  const { comment, noteId } = payload;

  if (state.selectedNote.note?.id === noteId) {
    if (state.selectedNote.note.comments) {
      state.selectedNote.note.comments = [
        comment,
        ...state.selectedNote.note.comments,
      ];
    } else {
      state.selectedNote.note.comments = [comment];
    }
  }
};

export default commentAdded;
