import { PayloadAction } from "@reduxjs/toolkit";
import { LicensesState } from "../LicensesSlice";

const licenseDeleted = (
  state: LicensesState,
  { payload }: PayloadAction<{ id: string }>
) => {
  const { id } = payload;

  state.licenses.forEach((license, index) => {
    if (license.id === id) {
      state.licenses.splice(index, 1);
    }
  });
};

export default licenseDeleted;
