import { PayloadAction } from "@reduxjs/toolkit";
import { FCPState } from "../FCPSlice";

const storeSetted = (
  state: FCPState,
  { payload }: PayloadAction<{ id: string | null; displayName: string | null }>
) => {
  state.general.store = payload;
};

export default storeSetted;
