import { PayloadAction } from "@reduxjs/toolkit";
import { NotesState } from "../notesSlice";

interface IPayload {
  drawer: "noteDetail" | "createNote";
  visibility: boolean;
}

const drawerSetted = (
  state: NotesState,
  { payload }: PayloadAction<IPayload>
) => {
  const { drawer, visibility } = payload;
  switch (drawer) {
    case "createNote":
      state.drawers.isCreateNoteV = visibility;
      break;
    case "noteDetail":
      state.drawers.isNoteDetailV = visibility;
      break;
    default:
      console.assert(false, "Invalid drawer type");
  }
};

export default drawerSetted;
