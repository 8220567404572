import attachmentAdded from "./attachmentAdded";
import commentAdded from "./commentAdded";
import commentEdited from "./commentEdited";
import drawerSetted from "./drawerSetted";
import noteDeleted from "./noteDeleted";
import noteEdited from "./noteEdited";
import notesLoaded from "./notesLoaded";
import selectedNoteSetted from "./selectedNoteSetted";

const notesReducers = {
  notesLoaded,
  drawerSetted,
  commentEdited,
  selectedNoteSetted,
  attachmentAdded,
  noteDeleted,
  noteEdited,
  commentAdded,
};

export default notesReducers;
