import { PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { getFullHousesSets_fullHouseSets_fullHouseSets } from "../../../../__generated/getFullHousesSets";
import { ShiftTemplatesState } from "../shiftTemplatesSlice";

interface IPayload {
  setId: string;
  setName: string;
}
const setAdded = (
  state: ShiftTemplatesState,
  { payload }: PayloadAction<IPayload>
) => {
  const { setId, setName } = payload;
  const newSet: getFullHousesSets_fullHouseSets_fullHouseSets = {
    __typename: "FullHouseSet",
    active: state.sets.length === 0,
    createdAt: moment().toISOString(),
    id: setId,
    name: setName,
    updatedAt: moment().toISOString(),
  };

  state.sets.push(newSet);
};

export default setAdded;
