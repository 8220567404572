import { PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import getDroppableColId from "../../Util/getDroppableColId";
import { ShiftState } from "../shiftsSlice";

interface IPayload {
  shiftId: string;
  startDate: string;
  userId: string | undefined;
}

const shiftsDeleted = (
  state: ShiftState,
  { payload }: PayloadAction<IPayload>
) => {
  const { shiftId, startDate, userId: loadedUesrId } = payload;
  const userId = loadedUesrId || "open";
  const droppableId = getDroppableColId({ userId, date: moment(startDate) });

  const droppableShifts = state.shifts.byDroppableId[droppableId];
  droppableShifts.forEach((shift, index) => {
    if (shift.id === shiftId) {
      droppableShifts.splice(index, 1);
    }
  });

  const userShifts = state.shifts.byUserId[userId];
  userShifts.forEach((shift, index) => {
    if (shift.id === shiftId) {
      userShifts.splice(index, 1);
    }
  });
};

export default shiftsDeleted;
