import { PayloadAction } from "@reduxjs/toolkit";
import { getStoreLicence_licenses_licenses } from "../../../__generated/getStoreLicence";
import { LicensesState } from "../LicensesSlice";

interface IPayload {
  licenses: getStoreLicence_licenses_licenses[];
  storeId: string;
}

const initialDataSetted = (
  state: LicensesState,
  { payload }: PayloadAction<IPayload>
) => {
  state.storeId = payload.storeId;
  state.licenses = payload.licenses;
};

export default initialDataSetted;
