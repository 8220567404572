import { PayloadAction } from "@reduxjs/toolkit";
import { ApplicantsState } from "../applicantsSlice";

const applicantNoteSetted = (
  state: ApplicantsState,
  { payload }: PayloadAction<{ note: string; applicantId: string }>
) => {
  const { applicantId, note } = payload;
  state.applicants.forEach((applicant, index) => {
    if (applicant.id === applicantId) {
      state.applicants[index] = {
        ...applicant,
        note,
      };
    }
  });

  if (state.selectedApplicant?.id === applicantId) {
    state.selectedApplicant = { ...state.selectedApplicant, note };
  }
};

export default applicantNoteSetted;
