import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const cache = new InMemoryCache({
  typePolicies: {
    User: {
      fields: {        
        branches: {
          merge(existing, incoming) {
            if (incoming === null) {
              if (!existing) {
                return null;
              } else {
                return existing;
              }
            } else {
              return incoming;
            }
          },
        },
        workingBranches: {
          merge(existing, incoming) {
            if (incoming === null) {
              if (!existing) {
                return null;
              } else {
                return existing;
              }
            } else {
              return incoming;
            }
          },
        },
      },
    },
    Query: {
      fields: {
        userLogs: {
          keyArgs: (args, context) => {
            if (args !== null) return args.input.id;
          },
          merge(existing, incoming) {
            if (!existing) {
              return incoming;
            }
            if (!incoming) {
              return existing;
            }
            return {
              ...existing,
              logs: [...existing.logs, ...incoming.logs],
            };
          },
        },
      },
    },
  },
});

const link = createHttpLink({
  uri: process.env.REACT_APP_BACKEND_URL_GRAPHQL,
  credentials: "include",
});

const client = new ApolloClient({
  cache,
  link: from([errorLink, link]),
});

export default client;
