import { useQuery } from "@apollo/client";
import React, { lazy } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { GET_MY_PROFILE } from "../Hooks/useQueryMyProfile";
import { getMyProfile } from "../__generated/getMyProfile";

const LoadingPage = lazy(() => import("./Routes/LoadingPage"));
const ServerDown = lazy(() => import("./Routes/ServerDown"));
const Routes = lazy(() => import("./Routes"));

const App = () => {
  const { data, loading, error } = useQuery<getMyProfile>(GET_MY_PROFILE);

  return (
    <>
      {loading && <LoadingPage />}
      {data ? (
        <>
          <Router>
            {!loading && <Routes isLoggedIn={data.myProfile.ok == true} />}
          </Router>
          <ToastContainer position="top-center" autoClose={2500} />
        </>
      ) : null}
      {!data && !loading && error && <Router><Routes isLoggedIn={false} /></Router>}
      {!data && !loading && !error && <ServerDown />}

    </>
  );
};

export default App;
