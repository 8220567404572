import { PayloadAction } from "@reduxjs/toolkit";
import { getBranchTimeOffs_timeOffs_timeOffs } from "../../../../__generated/getBranchTimeOffs";
import { PayrunState } from "../payrunSlice";

const selectedLeaveSetted = (
  state: PayrunState,
  { payload }: PayloadAction<getBranchTimeOffs_timeOffs_timeOffs>
) => {
  state.leaves.selectedLeave = payload;
};

export default selectedLeaveSetted;
