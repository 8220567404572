import drawerSetted from "./drawerSetted";
import payrunCreateDrawerPropertiesSetted from "./payrunCreateDrawerPropertiesSetted";
import payrunNoteSetted from "./payrunNoteSetted";
import payrunSummarySetted from "./payrunSumamrySetted";
import selectedDateSetted from "./selectedDateSetted";
import selectedShiftSetted from "./selectedShiftSetted";
import selectedUserIdSetted from "./selectedUserIdSetted";
import shiftAdded from "./shiftAdded";
import shiftDeleted from "./shiftDeleted";
import shiftEdited from "./shiftEdited";
import shiftsSetted from "./shiftsSetted";
import usersListSetted from "./usersListSetted";
import payrunSummaryDeleted
 from "./payrunSumamryDeleted";
const shiftReducers = {
  drawerSetted,
  selectedDateSetted,
  selectedUserIdSetted,
  selectedShiftSetted,
  shiftAdded,
  shiftDeleted,
  shiftEdited,
  shiftsSetted,
  usersListSetted,
  payrunNoteSetted,
  payrunSummarySetted,
  payrunSummaryDeleted,
  payrunCreateDrawerPropertiesSetted,
};

export default shiftReducers;
