import { PayloadAction } from "@reduxjs/toolkit";
import { IShift, ShiftState } from "../shiftsSlice";

const selectedShiftSetted = (
  state: ShiftState,
  { payload }: PayloadAction<IShift>
) => {
  state.selectedShift = payload;
};

export default selectedShiftSetted;
