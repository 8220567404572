import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootStore } from "../../../store";

interface INavState {
  isSideNavV: boolean;
  isMobileNavV: boolean;
  isBtmNavV: boolean;
}

const initialState: INavState = {
  isSideNavV: false,
  isMobileNavV: false,
  isBtmNavV: false,
};

const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setSideNavV(state: INavState, { payload }: PayloadAction<boolean>) {
      state.isSideNavV = payload;
    },
    setMobileNavV(state: INavState, { payload }: PayloadAction<boolean>) {
      state.isMobileNavV = payload;
    },
    setBottomNavV(state: INavState, { payload }: PayloadAction<boolean>) {
      state.isBtmNavV = payload;
    },
  },
});

const navSelectors = {
  selectSide: (state: RootStore) => state.layout.nav.isSideNavV,
  selectMobile: (state: RootStore) => state.layout.nav.isMobileNavV,
  selectBottom: (state: RootStore) => state.layout.nav.isBtmNavV,
};

export const useNav = {
  actions: navSlice.actions,
  selectors: navSelectors,
};

export default navSlice.reducer;
