import { PayloadAction } from "@reduxjs/toolkit";
import { getFCPTaskCounts_fCPTaskCounts_tasks } from "../../../__generated/getFCPTaskCounts";
import { FCPState } from "../FCPSlice";

const taskCountsLoaded = (
  state: FCPState,
  { payload }: PayloadAction<getFCPTaskCounts_fCPTaskCounts_tasks[]>
) => {
  const newTaskCounts: getFCPTaskCounts_fCPTaskCounts_tasks[] = [
    ...state.taskCounts,
  ];

  for (const newTask of payload) {
    let isValid = true;
    newTaskCounts.forEach((task, index) => {
      if (task.form === newTask.form && task.storeId === newTask.storeId) {
        newTaskCounts[index] = newTask;
        isValid = false;
      }
    });

    if (isValid) {
      newTaskCounts.push(newTask);
    }
  }
  state.taskCounts = newTaskCounts;
};

export default taskCountsLoaded;
