import { PayloadAction } from "@reduxjs/toolkit";
import { getFCPItems_fCPItems_items } from "../../../__generated/getFCPItems";
import { FCPState } from "../FCPSlice";

const itemEdited = (
  state: FCPState,
  { payload }: PayloadAction<getFCPItems_fCPItems_items>
) => {
  state.items.forEach((item, index) => {
    if (item.id === payload.id) {
      state.items[index] = payload;
    }
  });
};

export default itemEdited;
