import { PayloadAction } from "@reduxjs/toolkit";
import { FCPState } from "../FCPSlice";

const taskDeleted = (
  state: FCPState,
  { payload }: PayloadAction<{ id: string }>
) => {
  Object.keys(state.tasks).forEach((key) => {
    state.tasks[key as keyof typeof state.tasks].tasks.forEach(
      (task, index) => {
        if (task.id === payload.id) {
          state.tasks[key as keyof typeof state.tasks].tasks.splice(index, 1);
        }
      }
    );
  });
};

export default taskDeleted;
