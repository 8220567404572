import { PayloadAction } from "@reduxjs/toolkit";
import { getPayrunSummaries_payrunSummaries_payrunSummaries } from "../../../../__generated/getPayrunSummaries";
import { ShiftState } from "../shiftsSlice";

const payrunSummarySetted = (
  state: ShiftState,
  { payload }: PayloadAction<getPayrunSummaries_payrunSummaries_payrunSummaries>
) => {
  state.payrunSummary = payload;
};

export default payrunSummarySetted;
