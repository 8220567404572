import { PayloadAction } from "@reduxjs/toolkit";
import { IPublicStore, PublicFcpTasksState } from "../fcpTasksSlice";

interface IPayload {
  startDate: string;
  endDate: string;
  store: IPublicStore;
}

const generalInfoSetted = (
  state: PublicFcpTasksState,
  { payload }: PayloadAction<IPayload>
) => {
  state.general = { ...state.general, ...payload };
};

export default generalInfoSetted;
