import { PayloadAction } from "@reduxjs/toolkit";
import { FORM } from "../../../__generated/globalTypes";
import { FCPState } from "../FCPSlice";

const loadBtnOrloadCountSetted = (
  state: FCPState,
  {
    payload,
  }: PayloadAction<{ form: FORM; isLoadMoreBtnV?: boolean; loadCount?: number }>
) => {
  const { form, isLoadMoreBtnV, loadCount } = payload;
  if (isLoadMoreBtnV !== undefined) {
    state.tasks[form].isLoadMoreBtnV = isLoadMoreBtnV;
  }
  if (loadCount !== undefined) {
    state.tasks[form].loadCount = loadCount;
  }
};

export default loadBtnOrloadCountSetted;
