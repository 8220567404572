import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import experiment from "./Features/Experiment/experimentSlice";
import FCP from "./Features/FCP/FCPSlice";
import layout from "./Features/Layout/layoutReducer";
import licenses from "./Features/Licenses/LicensesSlice";
import notes from "./Features/Notes/notesSlice";
import publicReducer from "./Features/Public/publicReducer";
import schedule from "./Features/Schedule/scheduleReducer";
import staff from "./Features/Staff/staffReducer";

const store = configureStore({
  reducer: {
    public: publicReducer,
    experiment,
    schedule,
    layout,
    staff,
    notes,
    FCP,
    licenses,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootStore = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootStore> = useSelector;
export default store;
