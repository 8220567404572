import { PayloadAction } from "@reduxjs/toolkit";
import { LeavesState } from "../leavesSlice";

interface IPayload {
  drawer: "main" | "leaveDetail" | "leaveRequest";
  visibility: boolean;
}

const drawerSetted = (
  state: LeavesState,
  { payload }: PayloadAction<IPayload>
) => {
  const { drawer, visibility } = payload;
  switch (drawer) {
    case "main":
      state.drawers.isMainDrawerV = visibility;
      break;
    case "leaveDetail":
      state.drawers.isLeaveDetailDrawerV = visibility;
      break;
    case "leaveRequest":
      state.drawers.isLeaveRequestDrawerV = visibility;
      break;
    default:
      console.assert(false, "undefined drawer");
  }
};

export default drawerSetted;
