import { PayloadAction } from "@reduxjs/toolkit";
import { ApplicantsState } from "../applicantsSlice";

interface IPayload {
  drawer: "applicantDetail";
  visibility: boolean;
}

const drawerSetted = (
  state: ApplicantsState,
  { payload }: PayloadAction<IPayload>
) => {
  const { drawer, visibility } = payload;
  switch (drawer) {
    case "applicantDetail":
      state.drawers.isApplicantsDetailDrawerV = visibility;
      break;

    default:
      console.assert(false, "Invalid drawer type");
  }
};

export default drawerSetted;
