import { createSlice } from "@reduxjs/toolkit";
import { FORM } from "../../../__generated/globalTypes";
import fcpTasksReducers from "./Recuders";

export interface IPublicStore {
  id: string;
  displayName: string;
  address: string;
  startTimeMonday: string;
  endTimeMonday: string;
  startTimeTuesday: string;
  endTimeTuesday: string;
  startTimeWednesday: string;
  endTimeWednesday: string;
  startTimeThursday: string;
  endTimeThursday: string;
  startTimeFriday: string;
  endTimeFriday: string;
  startTimeSaturday: string;
  endTimeSaturday: string;
  startTimeSunday: string;
  endTimeSunday: string;
}

export interface IFcpTasksTask {
  id: string;
  record: string;
  submitted: string;
  createdAt: string;
  updatedAt: string;
  fromDate: string;
  dueDate: string;
  type: "issued" | "resolved" | "none";
  item: {
    id: string;
    name: string;
    description?: string;
    frequency: string;
  };
  user: {
    surname: string;
    givenName: string;
    displayName: string;
  };
}

export interface IFormInfo {
  needMore: boolean;
  count: number;
  tasks: IFcpTasksTask[];
}

export interface PublicFcpTasksState {
  general: {
    offset: number;
    activeForm: FORM | null;
    startDate?: string;
    endDate?: string;
    store?: IPublicStore;
    loading: boolean;
  };
  drawer: {
    isVisible: boolean;
    task: IFcpTasksTask | null;
  };
  tasks: { -readonly [key in keyof typeof FORM]: IFormInfo };
}

let defaultTasks: any;

Object.keys(FORM).forEach((key) => {
  defaultTasks = {
    ...defaultTasks,
    [key]: { count: 0, tasks: [], needMore: false },
  };
});

const initialState: PublicFcpTasksState = {
  general: {
    offset: 100,
    activeForm: null,
    loading: false,
  },
  drawer: {
    isVisible: false,
    task: null,
  },
  tasks: defaultTasks,
};

const fcpTasksSlice = createSlice({
  name: "fcpTasks",
  initialState,
  reducers: { ...fcpTasksReducers },
});

export const fcpTasksActions = fcpTasksSlice.actions;
export default fcpTasksSlice.reducer;
