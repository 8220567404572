import { PayloadAction } from "@reduxjs/toolkit";
import { getFullHouses_fullHouses_fullHouses } from "../../../../__generated/getFullHouses";
import { ShiftTemplatesState } from "../shiftTemplatesSlice";

interface IPayload {
  activeSetId: string;
  activeTemplates: getFullHouses_fullHouses_fullHouses[];
}
const activeSetAndTemplatesSetted = (
  state: ShiftTemplatesState,
  { payload }: PayloadAction<IPayload>
) => {
  const { activeSetId, activeTemplates } = payload;
  const activeSet = state.sets.find((set) => set.id === activeSetId);
  if (activeSet) {
    state.sets.forEach((set, index) => {
      if (set.id === activeSetId) {
        state.sets[index] = { ...set, active: true };
      } else {
        state.sets[index] = { ...set, active: false };
      }
    });
  }
  if (state.selectedSet?.id === activeSetId) {
    state.selectedSet = { ...state.selectedSet, active: true };
  }

  state.activeTemplates = activeTemplates;
};

export default activeSetAndTemplatesSetted;
