import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getNote_note_note } from "../../__generated/getNote";
import { getNotes_notes_notes } from "../../__generated/getNotes";
import notesReducers from "./Reducers";

export enum ENoteStateModes {
  "staff",
  "staffs",
  "branch",
}

export interface SelectedNoteForNotesState {
  status: "unset" | "loading" | "completed" | "failed";
  note: getNote_note_note | null;
}

export type LoadMoreCountForNotesState = {
  -readonly [key in keyof typeof ENoteStateModes]: number;
};

export interface NotesState {
  userId: string;
  mode: ENoteStateModes;
  loadMoreCount: LoadMoreCountForNotesState;
  defaultLoadMoreCount: 1000;
  notes: getNotes_notes_notes[];
  selectedNote: SelectedNoteForNotesState;
  drawers: {
    isCreateNoteV: boolean;
    isNoteDetailV: boolean;
  };
}

const initialState: NotesState = {
  mode: ENoteStateModes.staffs,
  loadMoreCount: {
    branch: 0,
    staffs: 0,
    staff: 0,
  },
  defaultLoadMoreCount: 1000,
  notes: [],
  userId: "",
  selectedNote: {
    status: "unset",
    note: null,
  },
  drawers: {
    isCreateNoteV: false,
    isNoteDetailV: false,
  },
};

const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    resetProps(
      state,
      {
        payload,
      }: PayloadAction<{ mode: ENoteStateModes; userId: string | undefined }>
    ) {
      const { mode, userId } = payload;
      state.selectedNote = {
        note: null,
        status: "unset",
      };
      state.mode = mode;
      state.userId = userId || "";
      state.drawers = {
        isCreateNoteV: false,
        isNoteDetailV: false,
      };
    },
    ...notesReducers,
  },
});

export const notesActions = notesSlice.actions;
export default notesSlice.reducer;
