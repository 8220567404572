import { PayloadAction } from "@reduxjs/toolkit";
import { ShiftTemplatesState } from "../shiftTemplatesSlice";

interface IPayload {
  drawer: "main" | "setAdd" | "templateAdd" | "templateDetail";
  visibility: boolean;
}

const drawerSetted = (
  state: ShiftTemplatesState,
  { payload }: PayloadAction<IPayload>
) => {
  const { drawer, visibility } = payload;
  switch (drawer) {
    case "setAdd":
      state.drawer.isSetAddDrawerV = visibility;
      break;
    case "templateAdd":
      state.drawer.isTemplateAddDrawerV = visibility;
      break;
    case "templateDetail":
      state.drawer.isTemplateDetailDrawerV = visibility;
      break;
    case "main":
      state.drawer.isMainDrawerV = visibility;
      break;
    default:
      console.assert(false, "undefined drawer");
  }
};

export default drawerSetted;
