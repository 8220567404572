import { PayloadAction } from "@reduxjs/toolkit";
import { FCPState } from "../FCPSlice";

const dateSetted = (
  state: FCPState,
  {
    payload,
  }: PayloadAction<{ startDate: string | null; endDate: string | null }>
) => {
  state.general.date = payload;
};

export default dateSetted;
