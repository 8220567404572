import { PayloadAction } from "@reduxjs/toolkit";
import { getNote_note_note_files } from "../../../__generated/getNote";
import { NotesState } from "../notesSlice";

interface IPayload {
  noteId: string;
  file: getNote_note_note_files;
}

const attachmentAdded = (
  state: NotesState,
  { payload }: PayloadAction<IPayload>
) => {
  const { file, noteId } = payload;
  if (state.selectedNote.note?.id === noteId) {
    if (state.selectedNote.note.files) {
      state.selectedNote.note.files = [file, ...state.selectedNote.note.files];
    } else {
      state.selectedNote.note.files = [file];
    }
  }
};

export default attachmentAdded;
