import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootStore } from "../../../store";
import { getPublicHolidays_publicHolidays_publicHolidays } from "./../../../__generated/getPublicHolidays";

const initialState: getPublicHolidays_publicHolidays_publicHolidays[] = [];

const publicHolidaySlice = createSlice({
  name: "publicHolidays",
  initialState,
  reducers: {
    setInitialStates(
      state,
      {
        payload,
      }: PayloadAction<getPublicHolidays_publicHolidays_publicHolidays[]>
    ) {
      return payload;
    },
  },
});

export const publicHolidaysActions = publicHolidaySlice.actions;
export const publicHolidaysSelectors = {
  publicHolidays: (state: RootStore) => state.schedule.publicHolidays,
};

export default publicHolidaySlice.reducer;
