import { PayloadAction } from "@reduxjs/toolkit";
import { ShiftTemplatesState } from "../shiftTemplatesSlice";

interface IPayload {
  selectedSetId: string;
}
const selectedSetSetted = (
  state: ShiftTemplatesState,
  { payload }: PayloadAction<IPayload>
) => {
  const { selectedSetId } = payload;
  const selecetdSet = state.sets.find((set) => set.id === selectedSetId);
  state.selectedSet = selecetdSet;
};

export default selectedSetSetted;
