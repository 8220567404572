import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { getMyProfile, getMyProfile_myProfile_user } from "../__generated/getMyProfile";

export const GET_MY_PROFILE = gql`
  query getMyProfile {
    myProfile {
      ok
      error
      user {
        id
        createdAt
        minHours
        maxHours
        userPrincipalName
        givenName
        surname
        middleName
        titleName
        displayName
        nickname
        otherMails
        mobilePhone
        isEmployed
        graphId
        irdNumber
        bankAccountNumber
        address
        birthday
        createdAt
        rate
        files {
          type
          id
          name
          createdAt
        }
        contracts {
          id
          branch {
            id
            displayName
            businessName
            address
          }
          type
          startDate
          endDate
          files {
            id
            name
            type
            createdAt
          }
        }
        contract {
          id
          branch {
            id
            displayName
          }
          type
          startDate
          endDate
        }  
        contractBranch {
          id
          displayName
          businessName
          address
          signature {
            id
          }
          initial {
            id
          }
        }
        notifications {
          id
          body
          url
        }           
        branches {
          id
          displayName
          businessName
          region
          address          
        }
        workingBranches {
          id
          displayName
          businessName
          region
          address   
        }
        stores {
          id
          displayName
          address
        }
        jobDescriptions {
          id
          position {
            id
            name
          }          
          files {
            id
          }
        }
        positions {
          id
          name
        }
        activeRanks {
          id
          type
          dispatchType
          rank {
            id
            name
          }
          branch {
            id
            displayName
            region            
          }
          permissions
        }
        visas {
          id
          createdAt
          updatedAt
          type
          userId
          jobTitle
          startDate
          expireDate
          notes
          files {
            id
            name
            type
            createdAt
          }
          contractBranch {
            id
            displayName
          }
        }
      }
    }
  }
`;


export const useQueryMyProfile: () => getMyProfile_myProfile_user | null | undefined = () => {
    const qr = useQuery<getMyProfile>(GET_MY_PROFILE);
    const user = qr.data?.myProfile.user;
    return user
};