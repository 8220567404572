import { combineReducers } from "@reduxjs/toolkit";
import generalReducer from "./General/generalSlice";
import leavesReducer from "./Leaves/leavesSlice";
import payrunReducer from "./Payrun/payrunSlice";
import publicHolidaysReducer from "./PublicHolidays/publicHolidaysSlice";
import shiftsReducer from "./Shifts/shiftsSlice";
import shiftTemplatesReducer from "./ShiftTemplates/shiftTemplatesSlice";

const scheduleReducer = combineReducers({
  general: generalReducer,
  shifts: shiftsReducer,
  shiftTemplates: shiftTemplatesReducer,
  publicHolidays: publicHolidaysReducer,
  leaves: leavesReducer,
  payrun: payrunReducer,
});

export default scheduleReducer;
