import { PayloadAction } from "@reduxjs/toolkit";
import {
  getFCPTasks_fCPTasks_tasks_item,
  getFCPTasks_fCPTasks_tasks_user,
} from "../../../__generated/getFCPTasks";
import { FORM } from "../../../__generated/globalTypes";
import { FCPState } from "../FCPSlice";

interface IPayload {
  id: string;
  form?: FORM;
  item?: getFCPTasks_fCPTasks_tasks_item | null;
  user?: getFCPTasks_fCPTasks_tasks_user | null;
  storeId?: string | null;
  record?: string | null;
  fromDate?: any;
  dueDate?: any;
  submitted?: any | null;
  createdAt?: any;
  updatedAt?: any;
}

const taskEdited = (state: FCPState, { payload }: PayloadAction<IPayload>) => {
  Object.keys(state.tasks).forEach((key) => {
    state.tasks[key as keyof typeof state.tasks].tasks.forEach(
      (task, index) => {
        if (task.id === payload.id) {
          state.tasks[key as keyof typeof state.tasks].tasks[index] = {
            ...state.tasks[key as keyof typeof state.tasks].tasks[index],
            ...payload,
          };
        }
      }
    );
  });

  if (state.drawers.taskDetailDrawer.selectedTask?.id === payload.id) {
    state.drawers.taskDetailDrawer.selectedTask = {
      ...state.drawers.taskDetailDrawer.selectedTask,
      ...payload,
    };
  }
};

export default taskEdited;
