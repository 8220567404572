import { PayloadAction } from "@reduxjs/toolkit";
import { NotesState } from "../notesSlice";

interface IPayload {
  commentId: string;
  body: string;
}

const commentEdited = (
  state: NotesState,
  { payload }: PayloadAction<IPayload>
) => {
  const { body, commentId } = payload;

  if (state.selectedNote.note) {
    for (let i = 0; i < state.selectedNote.note.comments.length; i++) {
      if (state.selectedNote.note.comments[i].id === commentId) {
        state.selectedNote.note.comments[i].body = body;
      }
    }
  }
};

export default commentEdited;
