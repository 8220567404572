import { PayloadAction } from "@reduxjs/toolkit";
import { IShiftsForState, ShiftState } from "../shiftsSlice";

const shiftsSetted = (
  state: ShiftState,
  { payload }: PayloadAction<IShiftsForState>
) => {
  state.shifts = payload;
};

export default shiftsSetted;
