import { PayloadAction } from "@reduxjs/toolkit";
import { getBranchTimeOffs_timeOffs_timeOffs } from "../../../../__generated/getBranchTimeOffs";
import { LeavesState } from "../leavesSlice";

const leaveAdded = (
  state: LeavesState,
  { payload }: PayloadAction<getBranchTimeOffs_timeOffs_timeOffs>
) => {
  state.timeOffs.push(payload);
};

export default leaveAdded;
