import { Spin } from "antd";
import React from "react";
import styled from "styled-components";

const Conatiner = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingPage = () => (
  <Conatiner>
    <Spin size="default" />
  </Conatiner>
);

export default LoadingPage;
