import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getBranchTimeOffs_timeOffs_timeOffs } from "./../../../__generated/getBranchTimeOffs";
import leavesReducers from "./Reducers";

export interface LeavesState {
  timeOffs: getBranchTimeOffs_timeOffs_timeOffs[];
  selectedTimeOff: getBranchTimeOffs_timeOffs_timeOffs | null;
  drawers: {
    isMainDrawerV: boolean;
    isLeaveDetailDrawerV: boolean;
    isLeaveRequestDrawerV: boolean;
  };
}

const initialState: LeavesState = {
  timeOffs: [],
  selectedTimeOff: null,
  drawers: {
    isLeaveDetailDrawerV: false,
    isMainDrawerV: false,
    isLeaveRequestDrawerV: false,
  },
};

const leavesSlice = createSlice({
  name: "leaves",
  initialState,
  reducers: {
    setInitialStates(
      state,
      { payload }: PayloadAction<getBranchTimeOffs_timeOffs_timeOffs[]>
    ) {
      return { ...initialState, timeOffs: payload };
    },
    ...leavesReducers,
  },
});

export const leavesActions = leavesSlice.actions;

export default leavesSlice.reducer;
