import { PayloadAction } from "@reduxjs/toolkit";
import { getApplicants_applicants_applicants } from "../../../../__generated/getApplicants";
import { ApplicantsState } from "../applicantsSlice";

const applicantEdited = (
  state: ApplicantsState,
  { payload }: PayloadAction<getApplicants_applicants_applicants>
) => {
  const { id } = payload;
  state.applicants.forEach((applicant) => {
    if (applicant.id === id) {
      applicant = {
        ...payload,
      };
    }
  });
};

export default applicantEdited;
