import { PayloadAction } from "@reduxjs/toolkit";
import { FCPState } from "../FCPSlice";

const itemDeleted = (
  state: FCPState,
  { payload }: PayloadAction<{ id: string }>
) => {
  const newItems = state.items.filter((item) => item.id !== payload.id);
  state.items = newItems;
};

export default itemDeleted;
