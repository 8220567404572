import { PayloadAction } from "@reduxjs/toolkit";
import { NotesState, SelectedNoteForNotesState } from "../notesSlice";

const selectedNoteSetted = (
  state: NotesState,
  { payload }: PayloadAction<SelectedNoteForNotesState>
) => {
  state.selectedNote = payload;
};

export default selectedNoteSetted;
