import applicantEdited from "./applicantEdited";
import applicantFavoriteToggled from "./applicantFavoriteToggled";
import applicantNoteSetted from "./applicantNoteSetted";
import applicantStatusSetted from "./applicantStatusSetted";
import drawerSetted from "./drawerSetted";
import selectedApplicantSetted from "./selectedApplicantSetted";

const applicantsReducers = {
  drawerSetted,
  selectedApplicantSetted,
  applicantEdited,
  applicantFavoriteToggled,
  applicantStatusSetted,
  applicantNoteSetted,
};

export default applicantsReducers;
