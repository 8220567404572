import { PayloadAction } from "@reduxjs/toolkit";
import { IFcpTasksTask, PublicFcpTasksState } from "../fcpTasksSlice";

const tasksLoaded = (
  state: PublicFcpTasksState,
  { payload }: PayloadAction<IFcpTasksTask[]>
) => {
  if (state.general.activeForm) {
    state.tasks[state.general.activeForm].tasks = [
      ...state.tasks[state.general.activeForm].tasks,
      ...payload,
    ];
    state.tasks[state.general.activeForm].count++;
    state.tasks[state.general.activeForm].needMore =
      payload.length >= state.general.offset;
  }
};

export default tasksLoaded;
