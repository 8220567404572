import { PayloadAction } from "@reduxjs/toolkit";
import { ShiftState } from "../shiftsSlice";

interface IPayload {
  drawer:
    | "shiftDetail"
    | "shiftCreate"
    | "payrunNote"
    | "payrunDetail"
    | "payrunCreate";
  visibility: boolean;
}

const drawerSetted = (
  state: ShiftState,
  { payload }: PayloadAction<IPayload>
) => {
  const { drawer, visibility } = payload;
  switch (drawer) {
    case "shiftCreate":
      state.drawers.isShiftCreateV = visibility;
      break;
    case "shiftDetail":
      state.drawers.isShiftDetailV = visibility;
      break;
    case "payrunNote":
      state.drawers.isPayrunNoteV = visibility;
      break;
    case "payrunDetail":
      state.drawers.isPayrunDetailV = visibility;
      break;
    case "payrunCreate":
      state.drawers.payrunCreateDrawer = {
        visibility,
        uncheckWorkingHoursNote: null,
        uncheckWorkingHoursUserIds: []
      };
      break;
    default:
      console.assert(false, "Invalid drawer type");
  }
};

export default drawerSetted;
