import activeSetAndTemplatesSetted from "./activeSetAndTemplatesSetted";
import drawerSetted from "./drawerSetted";
import selectedSetSetted from "./selectedSetSetted";
import selectedTemplateSetted from "./selectedTemplateSetted";
import selectedTemplatesSetted from "./selectedTemplatesSetted";
import setAdded from "./setAdded";
import setDeleted from "./setDeleted";
import templateAdded from "./templateAdded";
import templateDeleted from "./templateDeleted";
import templateEdited from "./templateEdited";

const shiftTemplatesReducers = {
  setAdded,
  setDeleted,
  selectedSetSetted,
  selectedTemplateSetted,
  selectedTemplatesSetted,
  activeSetAndTemplatesSetted,
  drawerSetted,
  templateAdded,
  templateDeleted,
  templateEdited,
};

export default shiftTemplatesReducers;
