import { PayloadAction } from "@reduxjs/toolkit";
import { IFcpTasksTask, PublicFcpTasksState } from "../fcpTasksSlice";

const drawerOpened = (
  state: PublicFcpTasksState,
  { payload }: PayloadAction<IFcpTasksTask>
) => {
  state.drawer = { isVisible: true, task: payload };
};

export default drawerOpened;
