import { PayloadAction } from "@reduxjs/toolkit";
import { PayrunState } from "../payrunSlice";

interface IPayload {
  drawer: "leaveDetail";
  visibility: boolean;
}

const drawerSetted = (
  state: PayrunState,
  { payload }: PayloadAction<IPayload>
) => {
  const { drawer, visibility } = payload;
  switch (drawer) {
    case "leaveDetail":
      state.drawers.isDetailDrawerV = visibility;
      break;

    default:
      console.assert(false, "Invalid drawer type");
  }
};

export default drawerSetted;
