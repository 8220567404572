import { PayloadAction } from "@reduxjs/toolkit";
import { getFCPItems_fCPItems_items } from "../../../__generated/getFCPItems";
import { FCPState } from "../FCPSlice";

const itemLoaded = (
  state: FCPState,
  { payload }: PayloadAction<getFCPItems_fCPItems_items[]>
) => {
  const newItems: getFCPItems_fCPItems_items[] = [...state.items];

  for (const newItem of payload) {
    let isValid = true;
    newItems.forEach((item, index) => {
      if (item.id === newItem.id) {
        newItems[index] = newItem;
        isValid = false;
      }
    });

    if (isValid) {
      newItems.push(newItem);
    }
  }
  state.items = newItems.sort((i1, i2) => i1.name.localeCompare(i2.name));
};

export default itemLoaded;
