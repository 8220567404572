import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getBranchTimeOffs_timeOffs_timeOffs } from "../../../__generated/getBranchTimeOffs";
import { getShifts_shifts_shifts } from "../../../__generated/getShifts";
import { getUsers_users_users } from "../../../__generated/getUsers";
import payrunReducers from "./Reducers";

export interface IPayrunShifts {
  week1: getShifts_shifts_shifts[];
  week2: getShifts_shifts_shifts[];
}

interface IPayrunLeaves {
  leaves: getBranchTimeOffs_timeOffs_timeOffs[];
  selectedLeave: getBranchTimeOffs_timeOffs_timeOffs | null;
}

export interface IPayrunSummary {
  note: string | null;
  submitted: boolean;
  createdAt: any;
  updatedAt: any;
}

export type PayrunState = {
  general: {
    branchId: string | null;
    branchDisplayName: string | null;
    payrunEndingDate: string | null;
    usersList: getUsers_users_users[];
  };
  shifts: IPayrunShifts;
  leaves: IPayrunLeaves;
  summaries: {
    week1: IPayrunSummary | null;
    week2: IPayrunSummary | null;
  };
  drawers: {
    isDetailDrawerV: boolean;
  };
};

const initialState: PayrunState = {
  general: {
    branchDisplayName: null,
    branchId: null,
    payrunEndingDate: null,
    usersList: [],
  },
  shifts: {
    week1: [],
    week2: [],
  },
  leaves: {
    leaves: [],
    selectedLeave: null,
  },
  summaries: {
    week1: null,
    week2: null,
  },
  drawers: {
    isDetailDrawerV: false,
  },
};

const payrunSlice = createSlice({
  name: "payrun",
  initialState,
  reducers: {
    setInitialStates(state, { payload }: PayloadAction<PayrunState>) {
      return { ...initialState, ...payload };
    },
    ...payrunReducers,
  },
});

export const payrunActions = payrunSlice.actions;

export default payrunSlice.reducer;
