import { PayloadAction } from "@reduxjs/toolkit";
import { ApplicantsState } from "../applicantsSlice";

const applicantFavoriteToggled = (
  state: ApplicantsState,
  { payload }: PayloadAction<{ id: string; isFavorite: boolean }>
) => {
  const { id, isFavorite } = payload;
  state.applicants.forEach((applicant, index) => {
    if (applicant.id === id) {
      state.applicants[index] = {
        ...applicant,
        favorite: isFavorite,
      };
    }
  });

  if (state.selectedApplicant?.id === id) {
    state.selectedApplicant = {
      ...state.selectedApplicant,
      favorite: isFavorite,
    };
  }
};

export default applicantFavoriteToggled;
