import { CheckOutlined } from "@ant-design/icons";
import moment from "moment";
import React from "react";

export const dateFormat = "YYYY-MM-DD";
export const timeFormat = "HH:mm";
export const urlDateFormat = "YYYY-MM-DD";
export const INFINITY_DATE = "9999-12-31T10:59:59.000Z";
export const convertExpireDate = (date: string) => {
  if (moment(INFINITY_DATE).isSameOrBefore(moment(date), "date")) {
    return <CheckOutlined style={{ color: "#1890FF" }} />;
  } else {
    return moment(date).format(dateFormat);
  }
};

export const minutesToString = (minutes: number) => {
  if (minutes >= 1440) {
    const days = Math.round(minutes / 1440)
    const remains = minutes - days * 1440
    return `${days} ${moment().startOf('month').add(remains, 'minutes').format(timeFormat)}`;
  } else {
    return `${moment().startOf('month').add(minutes, 'minutes').format(timeFormat)}`;
  }
}