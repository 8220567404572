import { PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import getDroppableColId from "../../Util/getDroppableColId";
import { IShift, ShiftState } from "../shiftsSlice";

interface IPayload {
  currentShift: IShift;
  newShift: IShift;
}

export const shiftSortFunc = (a: any, b: any) =>
  new Date(a.startDate).valueOf() - new Date(b.startDate).valueOf();

const shiftEdited = (
  state: ShiftState,
  { payload }: PayloadAction<IPayload>
) => {
  const { newShift, currentShift } = payload;

  const currentDroppableId = getDroppableColId({
    date: moment(currentShift.startDate),
    userId: currentShift.user?.id,
  });
  const newDroppableId = getDroppableColId({
    date: newShift.startDate,
    userId: newShift.user?.id,
  });

  const currentDroppableShifts = state.shifts.byDroppableId[currentDroppableId];
  const newDroppableShifts = state.shifts.byDroppableId[newDroppableId];
  currentDroppableShifts.forEach((shift, index) => {
    if (shift.id === currentShift.id) {
      currentDroppableShifts.splice(index, 1);
    }
  });
  newDroppableShifts.push(newShift);
  newDroppableShifts.sort(shiftSortFunc);

  const currentUserId = currentShift.user?.id || "open";
  const newUserId = newShift.user?.id || "open";

  const currentUserShifts = state.shifts.byUserId[currentUserId];
  const newUserShifts = state.shifts.byUserId[newUserId];
  currentUserShifts.forEach((shift, index) => {
    if (shift.id === currentShift.id) {
      currentUserShifts.splice(index, 1);
    }
  });
  newUserShifts.push(newShift);
  newUserShifts.sort(shiftSortFunc);
};

export default shiftEdited;
