import { PayloadAction } from "@reduxjs/toolkit";
import { getApplicants_applicants_applicants } from "../../../../__generated/getApplicants";
import { ApplicantsState } from "../applicantsSlice";

const selectedApplicantSetted = (
  state: ApplicantsState,
  { payload }: PayloadAction<getApplicants_applicants_applicants>
) => {
  state.selectedApplicant = payload;
};

export default selectedApplicantSetted;
