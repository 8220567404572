import { createSlice } from "@reduxjs/toolkit";
import { getStoreLicence_licenses_licenses } from "../../__generated/getStoreLicence";
import licensesReducers from "./Reducers";

export interface LicensesState {
  storeId: string;
  licenses: getStoreLicence_licenses_licenses[];
  drawers: {
    createLicense: { isDrawerV: boolean };
    licenseDetail: {
      isDrawerV: boolean;
      licenseId: string | null;
    };
  };
}

const initialState: LicensesState = {
  storeId: "",
  licenses: [],
  drawers: {
    licenseDetail: {
      isDrawerV: false,
      licenseId: null,
    },
    createLicense: {
      isDrawerV: false,
    },
  },
};

const licensesSlice = createSlice({
  name: "licenses",
  initialState,
  reducers: {
    resetProps(state) {
      return initialState;
    },
    ...licensesReducers,
  },
});

export const licensesActions = licensesSlice.actions;
export default licensesSlice.reducer;
