import { PayloadAction } from "@reduxjs/toolkit";
import { getFullHouses_fullHouses_fullHouses } from "../../../../__generated/getFullHouses";
import { ShiftTemplatesState } from "../shiftTemplatesSlice";

interface IPayload {
  setId: string;
  template: getFullHouses_fullHouses_fullHouses;
}
const templateEdited = (
  state: ShiftTemplatesState,
  { payload }: PayloadAction<IPayload>
) => {
  const { setId, template } = payload;

  if (state.selectedSet?.id === setId) {
    state.selectedTemplates.forEach((selectedTemplate, index) => {
      if (selectedTemplate.id === template.id) {
        state.selectedTemplates[index] = template;
      }
    });
  }

  if (state.sets.find((set) => set.id === setId)?.active) {
    state.activeTemplates.forEach((activeTemplate, index) => {
      if (activeTemplate.id === template.id) {
        state.activeTemplates[index] = template;
      }
    });
  }
};

export default templateEdited;
