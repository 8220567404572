import { PayloadAction } from "@reduxjs/toolkit";
import { ShiftTemplatesState } from "../shiftTemplatesSlice";

interface IPayload {
  deleteSetId: string;
}
const setDeleted = (
  state: ShiftTemplatesState,
  { payload }: PayloadAction<IPayload>
) => {
  const { deleteSetId } = payload;
  const selectedSetId = state.selectedSet?.id;

  state.sets.forEach((set, index) => {
    if (set.id === deleteSetId) {
      state.sets.splice(index, 1);
    }
  });
  if (deleteSetId === selectedSetId) {
    state.selectedSet = state.sets.filter((set) => set.active)[0];
  }
};

export default setDeleted;
