import { PayloadAction } from "@reduxjs/toolkit";
import { ShiftTemplatesState } from "../shiftTemplatesSlice";

interface IPayload {
  setId: string;
  templateId: string;
}
const templateDeleted = (
  state: ShiftTemplatesState,
  { payload }: PayloadAction<IPayload>
) => {
  const { setId, templateId } = payload;

  if (state.selectedSet?.id === setId) {
    state.selectedTemplates.forEach((template, index) => {
      if (template.id === templateId) {
        state.selectedTemplates.splice(index, 1);
      }
    });
  }

  if (state.sets.find((set) => set.id === setId)?.active) {
    state.activeTemplates.forEach((template, index) => {
      if (template.id === templateId) {
        state.activeTemplates.splice(index, 1);
      }
    });
  }
};

export default templateDeleted;
