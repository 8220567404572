import { createSlice } from "@reduxjs/toolkit";
import { getFCPItems_fCPItems_items } from "../../__generated/getFCPItems";
import { getFCPTaskCounts_fCPTaskCounts_tasks } from "../../__generated/getFCPTaskCounts";
import { FORM } from "../../__generated/globalTypes";
import { getFCPTasks_fCPTasks_tasks } from "./../../__generated/getFCPTasks";
import FCPReducers from "./Reducers";

export interface IFCPStateTask {
  isLoadMoreBtnV: boolean;
  loadCount: number;
  tasks: getFCPTasks_fCPTasks_tasks[];
}

export interface FCPState {
  general: {
    store: {
      id: string | null;
      displayName: string | null;
    };
    date: {
      startDate: string | null;
      endDate: string | null;
    };
    defaultFetchCount: number;
  };
  taskCounts: getFCPTaskCounts_fCPTaskCounts_tasks[];
  tasks: { -readonly [key in keyof typeof FORM]: IFCPStateTask };
  items: getFCPItems_fCPItems_items[];
  drawers: {
    createTaskDrawer: {
      mode: FORM;
      visibility: boolean;
    };
    createItemDrawer: {
      mode: FORM;
      visibility: boolean;
    };
    taskDetailDrawer: {
      selectedTask: getFCPTasks_fCPTasks_tasks | null;
      visibility: boolean;
    };
    itemDetailDrawer: {
      selectedItem: getFCPItems_fCPItems_items | null;
      visibility: boolean;
    };
    multiTasksDrawer: {
      selectedForm: FORM;
      visibility: boolean;
    };
  };
}

const initialFCPTaskState: IFCPStateTask = {
  isLoadMoreBtnV: true,
  loadCount: 0,
  tasks: [],
};

const initialTaskMap = new Map<FORM, IFCPStateTask>(
  Object.keys(FORM).map((key) => {
    return [FORM[key as keyof typeof FORM], initialFCPTaskState];
  })
);

export const initialFCPState: FCPState = {
  general: {
    store: { id: null, displayName: null },
    date: {
      startDate: null,
      endDate: null,
    },
    defaultFetchCount: 50,
  },
  taskCounts: [],
  items: [],
  tasks: Object.fromEntries(initialTaskMap) as {
    -readonly [key in keyof typeof FORM]: IFCPStateTask;
  },
  drawers: {
    createTaskDrawer: {
      mode: FORM.STORE_CHEMICAL_LIST,
      visibility: false,
    },
    createItemDrawer: {
      mode: FORM.STORE_SUSHIRICE_PH_RECORD,
      visibility: false,
    },
    taskDetailDrawer: { selectedTask: null, visibility: false },
    itemDetailDrawer: { selectedItem: null, visibility: false },
    multiTasksDrawer: {
      selectedForm: FORM.STORE_FRIDGE_TEMPERATURE,
      visibility: false,
    },
  },
};

const FCPSlice = createSlice({
  name: "FCP",
  initialState: initialFCPState,
  reducers: { ...FCPReducers },
});

export const FCPActions = FCPSlice.actions;
export default FCPSlice.reducer;
