import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getPayrunNotes_payrunNotes_payrunNotes } from "./../../../__generated/getPayrunNotes";
import { getPayrunSummaries_payrunSummaries_payrunSummaries } from "./../../../__generated/getPayrunSummaries";
import {
  getShifts_shifts_shifts,
  getShifts_shifts_shifts_user,
} from "./../../../__generated/getShifts";
import shiftReducers from "./Reducers";
import { IShiftUser } from "./ShiftsProvider/ShiftsDataGenerator";

export interface IShift extends getShifts_shifts_shifts {
  startDate: string;
  endDate: string;
}

export interface IShiftsForState {
  byDroppableId: { [key: string]: IShift[] };
  byUserId: { [key: string]: IShift[] };
}

interface ShiftStateSetting {
  status: "loading" | "loaded";
}

export interface ShiftState {
  setting: ShiftStateSetting;
  shifts: IShiftsForState;
  usersList: {
    usersList: IShiftUser[];
  };
  selectedShift: IShift | null;
  selectedUserId: string | null;
  selectedDate: string | null;
  payrunNote: getPayrunNotes_payrunNotes_payrunNotes | null;
  payrunSummary: getPayrunSummaries_payrunSummaries_payrunSummaries | null;
  drawers: {
    isShiftCreateV: boolean;
    isShiftDetailV: boolean;
    isPayrunNoteV: boolean;
    isPayrunDetailV: boolean;
    payrunCreateDrawer: {
      visibility: boolean;
      uncheckWorkingHoursUserIds: string[];
      uncheckWorkingHoursNote: string | null;
    };
  };
}

interface ISetInitialStates {
  shifts: IShiftsForState;
  usersList: {
    usersList: IShiftUser[];
  };
  payrunNote: getPayrunNotes_payrunNotes_payrunNotes | null;
  payrunSummary: getPayrunSummaries_payrunSummaries_payrunSummaries | null;
}

const initialState: ShiftState = {
  setting: { status: "loading" },
  shifts: {
    byDroppableId: {},
    byUserId: {},
  },
  usersList: {
    usersList: []
  },
  selectedShift: null,
  selectedUserId: null,
  selectedDate: null,
  payrunNote: null,
  payrunSummary: null,
  drawers: {
    isShiftCreateV: false,
    isShiftDetailV: false,
    isPayrunNoteV: false,
    isPayrunDetailV: false,
    payrunCreateDrawer: {
      visibility: false,
      uncheckWorkingHoursUserIds: [],
      uncheckWorkingHoursNote: null,
    },
  },
};

const shiftsSlice = createSlice({
  name: "shifts",
  initialState,
  reducers: {
    resetState(state) {
      return initialState;
    },
    setInitialStates(state, { payload }: PayloadAction<ISetInitialStates>) {
      return { ...initialState, ...payload, setting: { status: "loaded" } };
    },
    ...shiftReducers,
  },
});

export const shiftActions = shiftsSlice.actions;

export default shiftsSlice.reducer;
