import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import store from "../../../store";
import { Regions } from "../../../__generated/globalTypes";

type TGeneralState = {
  branch: {
    id: string;
    displayName: string;
    region: Regions;
  };
  monday: string;
} | null;

interface ISetInitialStates {
  branch: {
    id: string;
    displayName: string;
    region: Regions;
  };
  monday: string;
}

const initialState = null as TGeneralState;

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setInitialStates(state, { payload }: PayloadAction<ISetInitialStates>) {
      return payload;
    },
  },
});

export const generalActions = generalSlice.actions;

export const useGeneralInfo = () => {
  const generalInfo = store.getState().schedule.general;
  if (!generalInfo) {
    throw new Error("useGeneralInfo should be called in GeneralSetupContainer");
  } else {
    return generalInfo;
  }
};

export default generalSlice.reducer;
