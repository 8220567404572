import { PayloadAction } from "@reduxjs/toolkit";
import { getFullHouses_fullHouses_fullHouses } from "../../../../__generated/getFullHouses";
import { ShiftTemplatesState } from "../shiftTemplatesSlice";

const selectedTemplatesSetted = (
  state: ShiftTemplatesState,
  { payload }: PayloadAction<getFullHouses_fullHouses_fullHouses[]>
) => {
  state.selectedTemplates = payload;
};

export default selectedTemplatesSetted;
