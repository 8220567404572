import { PayloadAction } from "@reduxjs/toolkit";
import { ShiftState } from "../shiftsSlice";

const selectedUserIdSetted = (
  state: ShiftState,
  { payload }: PayloadAction<string | null>
) => {
  state.selectedUserId = payload;
};

export default selectedUserIdSetted;
