import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootStore } from "../../store";
import { getShifts_shifts_shifts } from "../../__generated/getShifts";

export interface IExperimentState {
  shifts: getShifts_shifts_shifts[];
  shiftsStatus: "idle" | "loading" | "succeeded" | "failed";
  shiftError: string | null;
  selectedShift: getShifts_shifts_shifts | undefined;
  isDrawerV: boolean;
}

const initialState: IExperimentState = {
  isDrawerV: false,
  shiftsStatus: "idle",
  shiftError: null,
  selectedShift: undefined,
  shifts: [],
};

const experimentSlice = createSlice({
  name: "experiment",
  initialState,
  reducers: {
    SetShiftStatus(
      state: IExperimentState,
      action: PayloadAction<"idle" | "loading" | "succeeded" | "failed">
    ) {
      state.shiftsStatus = action.payload;
    },
    SetShifts(
      state: IExperimentState,
      { payload }: PayloadAction<getShifts_shifts_shifts[]>
    ) {
      state.shifts = payload;
    },
    SetSelectedShift(
      state: IExperimentState,
      { payload }: PayloadAction<getShifts_shifts_shifts | undefined>
    ) {
      state.selectedShift = payload;
    },
    DrawerOpen(state: IExperimentState) {
      state.isDrawerV = true;
    },
    DrawerClose(state: IExperimentState) {
      state.isDrawerV = false;
    },
  },
});

export const {
  DrawerClose,
  DrawerOpen,
  SetShiftStatus,
  SetShifts,
  SetSelectedShift,
} = experimentSlice.actions;

export default experimentSlice.reducer;

export const ExperimentDrawerSelector = (state: RootStore) =>
  state.experiment.isDrawerV;
