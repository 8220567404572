import { ApolloProvider } from "@apollo/client";
import "antd/dist/antd.css";
import dotenv from "dotenv";
import moment from "moment-timezone";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import client from "./Apollo/Client";
import App from "./App/App";
import ErrorBoundary from "./App/Components/ErrorBoundary";
import LoadingPage from "./App/Routes/LoadingPage";
import store from "./store";

dotenv.config();
moment.tz.setDefault("Pacific/Auckland");
moment.locale("en", { week: { dow: 1 } }); // 0-6 (Sunday-Saturday)
ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ErrorBoundary>
        <Suspense fallback={<LoadingPage />}>
          <App />
        </Suspense>
      </ErrorBoundary>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);
