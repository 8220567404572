import { PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { getNotes_notes_notes } from "../../../__generated/getNotes";
import { ENoteStateModes, NotesState } from "../notesSlice";

interface IPayload {
  notes: getNotes_notes_notes[];
  mode?: ENoteStateModes;
}

const notesLoaded = (
  state: NotesState,
  { payload }: PayloadAction<IPayload>
) => {
  const { notes, mode } = payload;
  const newNotes = [...state.notes];

  for (const newNote of notes) {
    let isValid = true;
    for (const currentNote of state.notes) {
      if (currentNote.id === newNote.id) {
        isValid = false;
      }
    }
    if (isValid) {
      newNotes.push(newNote);
    }
  }
  newNotes.sort((a, b) => moment(b.createdAt).diff(a.createdAt));
  state.notes = newNotes;
  if (mode) {
    state.loadMoreCount[
      ENoteStateModes[mode] as keyof typeof ENoteStateModes
    ]++;
  }
};

export default notesLoaded;
