import { PayloadAction } from "@reduxjs/toolkit";
import { IShiftUser } from "../ShiftsProvider/ShiftsDataGenerator";
import { ShiftState } from "../shiftsSlice";

const usersListSetted = (
  state: ShiftState,
  { payload }: PayloadAction<IShiftUser[]>
) => {
  state.usersList.usersList = payload;
};

export default usersListSetted;
