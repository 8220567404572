import { PayloadAction } from "@reduxjs/toolkit";
import { NotesState } from "../notesSlice";

const noteDeleted = (
  state: NotesState,
  { payload }: PayloadAction<{ noteId: string }>
) => {
  const { noteId } = payload;
  state.notes.forEach((note, index) => {
    if (note.id === noteId) {
      state.notes.splice(index, 1);
    }
  });

  if (state.selectedNote.note?.id === noteId) {
    state.selectedNote = { status: "unset", note: null };
    state.drawers.isNoteDetailV = false;
  }
};

export default noteDeleted;
