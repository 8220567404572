import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getApplicants_applicants_applicants } from "./../../../__generated/getApplicants";
import applicantsReducers from "./Reducers";

export interface ApplicantSliceSetting {
  hiredOnly: boolean;
  archivedOnly: boolean;
  notSuitableOnly: boolean;
}

export interface ApplicantsState {
  applicants: getApplicants_applicants_applicants[];
  selectedApplicant: getApplicants_applicants_applicants | null;
  drawers: {
    isApplicantsDetailDrawerV: boolean;
  };
  setting: ApplicantSliceSetting;
}

interface ISetInitialState {
  applicants: getApplicants_applicants_applicants[];
  setting: ApplicantSliceSetting;
}

const initialState: ApplicantsState = {
  applicants: [],
  selectedApplicant: null,
  drawers: {
    isApplicantsDetailDrawerV: false,
  },
  setting: {
    hiredOnly: false,
    archivedOnly: false,
    notSuitableOnly: false,
  },
};

const applicantsSlice = createSlice({
  name: "applicants",
  initialState,
  reducers: {
    setInitialState(state, { payload }: PayloadAction<ISetInitialState>) {
      const { applicants, setting } = payload;

      const filteredApplicants = applicants.filter((applicant) => {
        let isValid = true;
        state.applicants.forEach((item) => {
          if (item.id === applicant.id) {
            isValid = false;
          }
        });
        return isValid;
      });

      state.applicants = [...state.applicants, ...filteredApplicants];
      state.setting = setting;
    },
    ...applicantsReducers,
  },
});

export const applicantsActions = applicantsSlice.actions;

export default applicantsSlice.reducer;
