import moment from "moment";
import { dateFormat } from "../../../Utils/moment";

interface IProps {
  userId: string | undefined;
  date: moment.Moment | string;
}

const getDroppableColId: (args: IProps) => string = ({ date, userId }) => {
  if (typeof date === "string") {
    return `${userId || "open"}#${moment(date).format(dateFormat)}`;
  } else {
    return `${userId || "open"}#${date.format(dateFormat)}`;
  }
};

export default getDroppableColId;
