import { PayloadAction } from "@reduxjs/toolkit";
import { getNote_note_note } from "../../../__generated/getNote";
import { NotesState } from "../notesSlice";

const noteEdited = (
  state: NotesState,
  { payload }: PayloadAction<getNote_note_note>
) => {
  state.notes.forEach((note, index) => {
    if (note.id === payload.id) {
      state.notes[index] = { ...payload };
    }
  });

  if (state.selectedNote.note?.id === payload.id) {
    state.selectedNote.note = payload;
  }
};

export default noteEdited;
