import drawerSetted from "./drawerSetted";
import initialDataSetted from "./initialDataSetted";
import licensesLoaded from "./licnesesLoaded";
import licenseDeleted from "./licenseDeleted";

const licensesReducers = {
  licensesLoaded,
  drawerSetted,
  initialDataSetted,
  licenseDeleted,
};

export default licensesReducers;
