import { PayloadAction } from "@reduxjs/toolkit";
import { ShiftState } from "../shiftsSlice";

interface IPayload {
  uncheckWorkingHoursUserIds?: string[];
  uncheckWorkingHoursNote?: null;
}

const payrunCreateDrawerPropertiesSetted = (
  state: ShiftState,
  { payload }: PayloadAction<IPayload>
) => {
  state.drawers.payrunCreateDrawer = {
    ...state.drawers.payrunCreateDrawer,
    ...payload,
  };
};

export default payrunCreateDrawerPropertiesSetted;
