import activeFormSetted from "./activeFormSetted";
import drawerClosed from "./drawerClosed";
import drawerOpened from "./drawerOpened";
import generalInfoSetted from "./generalInfoSetted";
import loadingSetted from "./loadingSetted";
import tasksLoaded from "./tasksLoaded";

const fcpTasksReducers = {
  activeFormSetted,
  generalInfoSetted,
  loadingSetted,
  tasksLoaded,
  drawerOpened,
  drawerClosed,
};

export default fcpTasksReducers;
