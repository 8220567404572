import { PayloadAction } from "@reduxjs/toolkit";
import { getFCPItems_fCPItems_items } from "../../../__generated/getFCPItems";
import { getFCPTasks_fCPTasks_tasks } from "../../../__generated/getFCPTasks";
import { FORM } from "../../../__generated/globalTypes";
import { FCPState } from "../FCPSlice";

type IPayload =
  | {
      drawer: "createItem";
      visibility: boolean;
      mode: FORM;
    }
  | {
      drawer: "createTask";
      visibility: boolean;
      mode: FORM;
    }
  | {
      drawer: "itemDetail";
      visibility: boolean;
      selectedItem: getFCPItems_fCPItems_items | null;
    }
  | {
      drawer: "taskDetail";
      visibility: boolean;
      selectedTask: getFCPTasks_fCPTasks_tasks | null;
    }
  | {
      drawer: "multipleTask";
      visibility: boolean;
      selectedForm: FORM;
    };

const drawerSetted = (
  state: FCPState,
  { payload }: PayloadAction<IPayload>
) => {
  switch (payload.drawer) {
    case "createItem":
      state.drawers.createItemDrawer = {
        mode: payload.mode,
        visibility: payload.visibility,
      };
      return;
    case "createTask":
      state.drawers.createTaskDrawer = {
        mode: payload.mode,
        visibility: payload.visibility,
      };
      return;
    case "itemDetail":
      state.drawers.itemDetailDrawer = {
        selectedItem: payload.selectedItem,
        visibility: payload.visibility,
      };
      return;
    case "taskDetail":
      state.drawers.taskDetailDrawer = {
        selectedTask: payload.selectedTask,
        visibility: payload.visibility,
      };
      return;
    case "multipleTask":
      state.drawers.multiTasksDrawer = {
        ...payload,
      };
      return;
    default:
      console.assert(false, "Invalid type");
  }
};

export default drawerSetted;
