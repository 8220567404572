import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getFullHouses_fullHouses_fullHouses } from "../../../__generated/getFullHouses";
import { getFullHousesSets_fullHouseSets_fullHouseSets } from "../../../__generated/getFullHousesSets";
import shiftTemplatesReducers from "./Reducers";

export interface ShiftTemplatesState {
  sets: getFullHousesSets_fullHouseSets_fullHouseSets[];
  selectedSet: getFullHousesSets_fullHouseSets_fullHouseSets | undefined;
  selectedTemplates: getFullHouses_fullHouses_fullHouses[];
  activeTemplates: getFullHouses_fullHouses_fullHouses[];
  selectedTemplate: getFullHouses_fullHouses_fullHouses | undefined;
  drawer: {
    isMainDrawerV: boolean;
    isSetAddDrawerV: boolean;
    isTemplateDetailDrawerV: boolean;
    isTemplateAddDrawerV: boolean;
  };
}

interface ISetInitialStates {
  sets: getFullHousesSets_fullHouseSets_fullHouseSets[];
  selectedSet: getFullHousesSets_fullHouseSets_fullHouseSets | undefined;
  selectedTemplates: getFullHouses_fullHouses_fullHouses[];
  activeTemplates: getFullHouses_fullHouses_fullHouses[];
}

const initialState: ShiftTemplatesState = {
  sets: [],
  selectedSet: undefined,
  selectedTemplates: [],
  selectedTemplate: undefined,
  activeTemplates: [],
  drawer: {
    isMainDrawerV: false,
    isSetAddDrawerV: false,
    isTemplateDetailDrawerV: false,
    isTemplateAddDrawerV: false,
  },
};

const shiftTemplatesSlice = createSlice({
  name: "shiftTemplates",
  initialState,
  reducers: {
    setInitialStates(state, { payload }: PayloadAction<ISetInitialStates>) {
      return { ...initialState, ...payload };
    },
    ...shiftTemplatesReducers,
  },
});

export const shiftTemplatesActions = shiftTemplatesSlice.actions;

export default shiftTemplatesSlice.reducer;
