import { PayloadAction } from "@reduxjs/toolkit";
import { LeavesState } from "../leavesSlice";

interface IProps {
  deleteLeaveId: string;
}

const leaveDeleted = (
  state: LeavesState,
  { payload }: PayloadAction<IProps>
) => {
  const { deleteLeaveId } = payload;

  state.timeOffs.forEach((timeOff, index) => {
    if (timeOff.id === deleteLeaveId) {
      state.timeOffs.splice(index, 1);
    }
  });

  if (state.selectedTimeOff?.id === deleteLeaveId) {
    state.selectedTimeOff = null;
  }
};

export default leaveDeleted;
