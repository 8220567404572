import { PayloadAction } from "@reduxjs/toolkit";
import { FORM } from "../../../../__generated/globalTypes";
import { PublicFcpTasksState } from "../fcpTasksSlice";

const activeFormSetted = (
  state: PublicFcpTasksState,
  { payload }: PayloadAction<FORM>
) => {
  state.general.activeForm = payload;
};

export default activeFormSetted;
