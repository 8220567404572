import dateSetted from "./dateSetted";
import drawerSetted from "./drawerSetted";
import itemDeleted from "./itemDeleted";
import itemEdited from "./itemEdited";
import itemLoaded from "./itemLoaded";
import loadBtnOrloadCountSetted from "./loadBtnOrloadCountSetted";
import storeSetted from "./storeSetted";
import taskCountsLoaded from "./taskCountsLoaded";
import taskDeleted from "./taskDeleted";
import taskEdited from "./taskEdited";
import tasksLoaded from "./tasksLoaded";
import tasksReseted from "./tasksReseted";

const FCPReducers = {
  storeSetted,
  dateSetted,
  drawerSetted,
  tasksLoaded,
  taskEdited,
  taskDeleted,
  tasksReseted,
  taskCountsLoaded,
  itemLoaded,
  itemEdited,
  itemDeleted,
  loadBtnOrloadCountSetted,
};

export default FCPReducers;
