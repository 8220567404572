import { PayloadAction } from "@reduxjs/toolkit";
import { LicensesState } from "../LicensesSlice";

type IPayload =
  | {
      drawerType: "createLicense";
      isDrawerV: boolean;
    }
  | {
      drawerType: "licenseDetail";
      licenseId: string | null;
      isDrawerV: boolean;
    };

const drawerSetted = (
  state: LicensesState,
  { payload }: PayloadAction<IPayload>
) => {
  switch (payload.drawerType) {
    case "createLicense":
      state.drawers.createLicense = payload;
      break;
    case "licenseDetail":
      state.drawers.licenseDetail = payload;
      break;
    default:
      console.assert(false, "Invalid drawer type");
  }
};

export default drawerSetted;
