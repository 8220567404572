import { PayloadAction } from "@reduxjs/toolkit";

import { ShiftState } from "../shiftsSlice";

const selectedDateSetted = (
  state: ShiftState,
  { payload }: PayloadAction<string | null>
) => {
  state.selectedDate = payload;
};

export default selectedDateSetted;
