import { PayloadAction } from "@reduxjs/toolkit";
import { getPayrunNotes_payrunNotes_payrunNotes } from "../../../../__generated/getPayrunNotes";
import { ShiftState } from "../shiftsSlice";

const payrunNoteSetted = (
  state: ShiftState,
  { payload }: PayloadAction<getPayrunNotes_payrunNotes_payrunNotes>
) => {
  state.payrunNote = payload;
};

export default payrunNoteSetted;
