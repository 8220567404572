import { PayloadAction } from "@reduxjs/toolkit";
import {
  getShifts_shifts_shifts_timesheet,
  getShifts_shifts_shifts_user,
} from "../../../../__generated/getShifts";
import getDroppableColId from "../../Util/getDroppableColId";
import { ShiftState } from "../shiftsSlice";
import { shiftSortFunc } from "./shiftEdited";

interface IPayload {
  __typename: "Shift";
  id: string;
  branchId: string;
  color: string | null;
  user: getShifts_shifts_shifts_user | null;
  timesheet: getShifts_shifts_shifts_timesheet | null;
  displayName: string | null;
  notes: string | null;
  startDate: any;
  endDate: any;
  breakMinutes: number;
  isAgreed: boolean | null;
  isPublished: boolean | null;
  agreed: any | null;
}

const shiftAdded = (
  state: ShiftState,
  { payload }: PayloadAction<IPayload>
) => {
  const droppableId = getDroppableColId({
    date: payload.startDate,
    userId: payload.user?.id,
  });
  const userId = payload.user?.id || "open";
  const droppableShifts = state.shifts.byDroppableId[droppableId];
  droppableShifts.push(payload);
  droppableShifts.sort(shiftSortFunc);

  const userShifts = state.shifts.byUserId[userId];
  userShifts.push(payload);
  userShifts.sort(shiftSortFunc);
};

export default shiftAdded;
