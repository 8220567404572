import { PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { getFCPTasks_fCPTasks_tasks } from "../../../__generated/getFCPTasks";
import { FCPState } from "../FCPSlice";

const tasksLoaded = (
  state: FCPState,
  { payload }: PayloadAction<getFCPTasks_fCPTasks_tasks[]>
) => {
  payload.forEach((newTask) => {
    let isValid = true;
    state.tasks[newTask.form as keyof typeof state.tasks].tasks.forEach(
      (task, index) => {
        if (task.id === newTask.id) {
          state.tasks[newTask.form as keyof typeof state.tasks].tasks[index] =
            newTask;
          isValid = false;
        }
      }
    );
    if (isValid) {
      state.tasks[newTask.form as keyof typeof state.tasks].tasks.push(newTask);
      const taskList = [
        ...state.tasks[newTask.form as keyof typeof state.tasks].tasks,
      ].sort((t1, t2) => moment(t1.dueDate).diff(moment(t2.dueDate)));
      state.tasks[newTask.form as keyof typeof state.tasks].tasks = taskList;
    }
  });
};

export default tasksLoaded;
