import { PayloadAction } from "@reduxjs/toolkit";
import { ApplicationStatus } from "../../../../__generated/globalTypes";
import { ApplicantsState } from "../applicantsSlice";

const applicantStatusSetted = (
  state: ApplicantsState,
  { payload }: PayloadAction<{ status: ApplicationStatus; applicantId: string }>
) => {
  const { applicantId, status } = payload;
  state.applicants.forEach((applicant, index) => {
    if (applicant.id === applicantId) {
      state.applicants[index] = {
        ...applicant,
        status,
      };
    }
  });

  if (state.selectedApplicant?.id === applicantId) {
    state.selectedApplicant = { ...state.selectedApplicant, status };
  }
};

export default applicantStatusSetted;
