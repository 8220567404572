import { PayloadAction } from "@reduxjs/toolkit";
import { PublicFcpTasksState } from "../fcpTasksSlice";

const loadingSetted = (
  state: PublicFcpTasksState,
  { payload }: PayloadAction<boolean>
) => {
  state.general.loading = payload;
};

export default loadingSetted;
