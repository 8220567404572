import { PayloadAction } from "@reduxjs/toolkit";
import { getStoreLicence_licenses_licenses } from "../../../__generated/getStoreLicence";
import { LicensesState } from "../LicensesSlice";

const licensesLoaded = (
  state: LicensesState,
  { payload }: PayloadAction<getStoreLicence_licenses_licenses[]>
) => {
  const newLicenses = [...state.licenses];

  payload.forEach((license) => {
    let isValid = true;
    for (let i = 0; i < newLicenses.length; i++) {
      if (newLicenses[i].id === license.id) {
        newLicenses[i] = license;
        isValid = false;
        break;
      }
    }
    if (isValid) {
      newLicenses.push(license);
    }
  });

  state.licenses = newLicenses;
};

export default licensesLoaded;
