import drawerSetted from "./drawerSetted";
import leaveAdded from "./leaveAdded";
import leaveDeleted from "./leaveDeleted";
import leaveEdited from "./leaveEdited";
import selectedLeaveSetted from "./selectedLeaveSetted";

const leavesReducers = {
  drawerSetted,
  leaveAdded,
  leaveEdited,
  leaveDeleted,
  selectedLeaveSetted,
};

export default leavesReducers;
